import React from 'react'
import CategoryContainerItem from './CategoryContainerItem'
import { ThemeOptions, CategoriesDTO } from '../../../types'

interface CategoryContainerProps<T> {
    categories: T[]
    category: CategoriesDTO
    selection: number
    options: ThemeOptions
}

export default <T extends any>(Props: CategoryContainerProps<T>) => {
    const { categories, category, selection, options } = Props
    return (
        <section className="category-container container">
            <h2
                style={{ color: options.theme.color.primary }}
                dangerouslySetInnerHTML={{ __html: category.name }}
            />
            <ul className="row">
                {categories
                    .filter(_category => _category.category === selection)
                    .map((_category, index) => (
                        <CategoryContainerItem
                            key={index}
                            item={_category}
                            options={options}
                        />
                    ))}
            </ul>
        </section>
    )
}
