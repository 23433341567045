import React, { useState } from 'react'
import './style.css'
import { ThemeOptions } from '../../types'

interface SearchHeaderProps {
    headerText: string
    subHeaderText: string
    backgroundImage: string
    options?: ThemeOptions
}

export const SearchHeader = ({
    headerText,
    subHeaderText,
    backgroundImage,
    options,
}: SearchHeaderProps) => {
    const [search, setSearch] = useState<string>()
    return (
        <section
            className="search-header"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <div className="text-container">
                <h2>{headerText}</h2>
                <h3
                    style={{
                        color: options.theme.color.secondary,
                    }}
                >
                    {subHeaderText}
                </h3>
                {/* <input
                    placeholder={'Search Help & Advice'}
                    value={search}
                    onChange={event => setSearch(event.target.value)}
                /> */}
            </div>
        </section>
    )
}

export default SearchHeader
