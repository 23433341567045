import React, { useState } from 'react'
import './style.css'
import { Link } from 'gatsby'
import { ThemeOptions } from '../../../types'

interface CategoryContainerItem<T> {
    item: T
    options: ThemeOptions
}

export default <T extends any>(Props: CategoryContainerItem<T>) => {
    const { item, options } = Props
    const [onHover, setOnHover] = useState<boolean>(false)
    return (
        <li
            className="category-container-item col-lg-4"
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
        >
            <Link to={`/first-home-buyer-advice/` + item.url}>
                <img src={item.image} alt={item.url} />
                <h4
                    style={{
                        color: onHover
                            ? options.theme.color.secondary
                            : options.theme.color.primary,
                    }}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                />
            </Link>
        </li>
    )
}
