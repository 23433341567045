import React, { useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { ThemeProvider } from '../context/theme'
import SearchHeader from '../components/SearchHeader'
import SubscribeForm from '../components/SubscribeForm'
import { SiteConfig } from '../types/ContentBuilder'
import { AdvicesRequest, CategoriesRequest } from '../types/ExternalApi'
import CategoryNavigation from '../components/Category/Navigation'
import CategoryContainer from '../components/Category/Container'
import { AdvicesDTO, CategoriesDTO } from '../types'

const themeDefault = {
    color: {
        primary: '#001689',
        secondary: '#1dbbb2',
        third: 'grey',
    },
}

const searchHeaderBackgroundImage = require('../images/search_background_image.png')

interface HelpAdviceProps {
    pageContext: {
        advices: AdvicesRequest[]
        title: string
        categoryId: number
        config: SiteConfig
        categories: CategoriesRequest[]
    }
}

export default ({ pageContext }: HelpAdviceProps) => {
    const categoryId: number = pageContext.categoryId
    const advices: AdvicesDTO[] = pageContext.advices.map(advice => {
        let image: string = ''
        if (
            advice._embedded['wp:featuredmedia'][0]['media_details'] &&
            advice._embedded['wp:featuredmedia'][0]['media_details'].sizes &&
            advice._embedded['wp:featuredmedia'][0]['media_details'].sizes
                .medium_large
        )
            image =
                advice._embedded['wp:featuredmedia'][0]['media_details'].sizes
                    .medium_large.source_url
        else if (advice._embedded['wp:featuredmedia'][0])
            image = advice._embedded['wp:featuredmedia'][0].source_url
        return {
            title: advice.title.rendered,
            image,
            url: advice.slug,
            category: advice.category[0] - 1,
        } as AdvicesDTO
    })
    const categories: CategoriesDTO[] = pageContext.categories
    const [activeNavigationItem, setActiveNavigationItem] = useState<number>(
        categoryId - 1
    )
    return (
        <ThemeProvider value={themeDefault}>
            <Layout>
                <SEO title={pageContext.title} />
                <SearchHeader
                    headerText={`"I wish I'd know then, what I know now"`}
                    subHeaderText={'Well now you can!'}
                    backgroundImage={searchHeaderBackgroundImage}
                    options={{ theme: themeDefault }}
                />
                <CategoryNavigation<CategoriesDTO>
                    categories={categories}
                    active={activeNavigationItem}
                    setActive={setActiveNavigationItem}
                    options={{ theme: themeDefault }}
                />
                <CategoryContainer<AdvicesDTO>
                    category={categories[activeNavigationItem]}
                    categories={advices}
                    selection={activeNavigationItem}
                    options={{ theme: themeDefault }}
                />
                <SubscribeForm
                    theme={themeDefault}
                    config={pageContext.config}
                />
            </Layout>
        </ThemeProvider>
    )
}
