import React, { useState, useEffect } from 'react'
import { ThemeOptions } from '../../../types'
import { Link } from 'gatsby'

interface CategoryNavigationItemProps<T> {
    item: T
    index: number
    active: number
    setActive: Function
    options: ThemeOptions
}

export default <T extends any>(Props: CategoryNavigationItemProps<T>) => {
    const { item, index, active, setActive, options } = Props
    const [onHover, setOnHover] = useState<boolean>(false)
    useEffect(() => {
        if (
            typeof window !== `undefined` &&
            typeof window.location !== `undefined`
        )
            if (window.location.hash === '#' + item.value) setActive(index)
    }, [])
    return (
        <li>
            <Link
                key={index}
                to={`/first-home-buyer-advice/${item.slug}`}
                style={{
                    color:
                        active === index || onHover
                            ? options.theme.color.primary
                            : 'grey',
                    borderBottom:
                        active === index || onHover
                            ? `1px solid ${options.theme.color.primary}`
                            : `1px solid white`,
                }}
                onMouseEnter={() => setOnHover(true)}
                onMouseLeave={() => setOnHover(false)}
            >
                <span dangerouslySetInnerHTML={{ __html: item.name }} />
            </Link>
        </li>
    )
}
