import React from 'react'
import './style.css'
import { ThemeOptions } from '../../../types'
import CategoryNavigationItem from './CategoryNavigationItem'

interface CategoryNavigationProps<T> {
    categories: T[]
    active: number
    setActive: Function
    options: ThemeOptions
}

export default <T extends any>(Props: CategoryNavigationProps<T>) => {
    const { categories, active, setActive, options } = Props
    return (
        <section className="category-navigation container">
            <ul>
                {categories.map((advice, index) => (
                    <CategoryNavigationItem<T>
                        item={advice}
                        index={index}
                        active={active}
                        setActive={setActive}
                        options={options}
                    />
                ))}
            </ul>
        </section>
    )
}
